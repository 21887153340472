import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import _ from 'lodash'
import { lighten } from 'polished'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import moment from 'moment'
import PropTypes from 'prop-types'

import { colors } from '../styles/colors'

const PostCardStyles = css`
  flex: 1 1 300px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 20px 40px;
  min-height: 300px;
  background: #fff center center;
  background-size: cover;
  border-radius: 5px;
  box-shadow: rgba(39, 44, 49, 0.06) 8px 14px 38px, rgba(39, 44, 49, 0.03) 1px 3px 8px;
  transition: all 0.5s ease;

  :hover {
    box-shadow: rgba(39, 44, 49, 0.07) 8px 28px 50px, rgba(39, 44, 49, 0.04) 1px 6px 12px;
    transition: all 0.4s ease;
    transform: translate3D(0, -1px, 0) scale(1.02);
  }
`

const PostCardImageLink = css`
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
`

const PostCardImage = styled.div`
  width: auto;
  height: 200px;
  background: #fff no-repeat center center;
  background-size: cover;
`

const PostCardContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const PostCardContentLink = css`
  position: relative;
  flex-grow: 1;
  display: block;
  padding: 25px 25px 0;
  color: ${colors.darkgrey};

  :hover {
    text-decoration: none;
  }
`

const PostCardTags = styled.span`
  display: block;
  margin-bottom: 4px;
  color: ${colors.midgrey};
  font-size: 1.2rem;
  line-height: 1.15em;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`

const PostCardTitle = styled.h2`
  margin-top: 0;
`

const PostCardExcerpt = styled.section`
  font-family: Georgia, serif;
`

const PostCardMeta = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 25px 25px;
`

const AuthorList = styled.ul`
  display: flex;
  flex-wrap: wrap-reverse;
  margin: 0;
  padding: 0;
  list-style: none;
`

const AuthorListItem = styled.li`
  position: relative;
  flex-shrink: 0;
  margin: 0;
  padding: 0;

  :nth-of-type(1) {
    z-index: 10;
  }
  :nth-of-type(2) {
    z-index: 9;
  }
  :nth-of-type(3) {
    z-index: 8;
  }
  :nth-of-type(4) {
    z-index: 7;
  }
  :nth-of-type(5) {
    z-index: 6;
  }
  :nth-of-type(6) {
    z-index: 5;
  }
  :nth-of-type(7) {
    z-index: 4;
  }
  :nth-of-type(8) {
    z-index: 3;
  }
  :nth-of-type(9) {
    z-index: 2;
  }
  :nth-of-type(10) {
    z-index: 1;
  }
  :hover .author-name-tooltip {
    opacity: 1;
    transform: translateY(0px);
  }
`

const AuthorNameTooltip = styled.div`
  position: absolute;
  bottom: 105%;
  z-index: 999;
  display: block;
  padding: 2px 8px;
  color: white;
  font-size: 1.2rem;
  letter-spacing: 0.2px;
  white-space: nowrap;
  background: ${colors.darkgrey};
  border-radius: 3px;
  box-shadow: rgba(39, 44, 49, 0.08) 0 12px 26px, rgba(39, 44, 49, 0.03) 1px 3px 8px;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transform: translateY(6px);
  pointer-events: none;

  @media (max-width: 650px) {
    display: none;
  }
`

const StaticAvatar = css`
  display: block;
  overflow: hidden;
  margin: 0 -5px;
  width: 34px;
  height: 34px;
  border: #fff 2px solid;
  border-radius: 100%;
`

const AuthorProfileImage = css`
  display: block;
  width: 100%;
  height: 100%;
  /* background: color(var(--lightgrey) l(+10%)); */
  background: ${lighten('0.1', colors.lightgrey)}
  border-radius: 100%;
  object-fit: cover;
`
const ReadingTime = styled.span`
  flex-shrink: 0;
  margin-left: 20px;
  color: ${colors.midgrey};
  font-size: 1.2rem;
  line-height: 33px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`

const PostCard = ({ post }) => {
  const imgStyle = {}

  if (post.heroImage && post.heroImage.fluid.aspectRatio > 2) imgStyle.objectFit = 'contain'
  return (
    <article className={`post-card ${!_.has(post, 'heroImage.fluid') ? 'no-image' : ''}`} css={PostCardStyles}>
      {_.has(post, 'heroImage.fluid') && (
        <Link className="post-card-image-link" css={PostCardImageLink} to={`/${post.slug}`}>
          <PostCardImage className="post-card-image">
            {post.heroImage.fluid && (
              <Img
                imgStyle={imgStyle}
                alt={`${post.title} cover image`}
                style={{ height: '100%' }}
                fluid={post.heroImage.fluid}
              />
            )}
          </PostCardImage>
        </Link>
      )}
      <PostCardContent className="post-card-content">
        <Link className="post-card-content-link" css={PostCardContentLink} to={`/${post.slug}`}>
          <header className="post-card-header">
            {post.tags && (
              <PostCardTags>{`${post.tags[0]} - ${moment(post.publishDate)
                .local()
                .format('MMMM Do YYYY')}`}</PostCardTags>
            )}
            {!post.tags && (
              <PostCardTags>
                {moment(post.publishDate)
                  .local()
                  .format('MMMM Do YYYY')}
              </PostCardTags>
            )}
            <PostCardTitle>{post.title}</PostCardTitle>
          </header>
          <PostCardExcerpt>
            <p>{post.body.childMarkdownRemark.excerpt}</p>
          </PostCardExcerpt>
        </Link>
        <PostCardMeta className="post-card-meta">
          <AuthorList>
            <AuthorListItem>
              <AuthorNameTooltip className="author-name-tooltip">{post.author.name}</AuthorNameTooltip>
              <Link css={StaticAvatar} to="/about-me">
                <Img css={AuthorProfileImage} fluid={post.author.image.fluid} alt={post.author.name} />
              </Link>
            </AuthorListItem>
          </AuthorList>
          <ReadingTime>{post.body.childMarkdownRemark.timeToRead} min read</ReadingTime>
        </PostCardMeta>
      </PostCardContent>
    </article>
  )
}

PostCard.propTypes = {
  post: PropTypes.object.isRequired,
}

export default PostCard
